import React from 'react';

function Footer(props) {
  return (
    <footer id="footer"  className={["footer-wrapper", props.isMobile ? "phoneFooter" : ""].join(' ')}  >
      <span>Copyright © 2019 CodeBlock All Rights Reserved</span>
    </footer>
  );
}


export default Footer;
