import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Button, Popover,message } from 'antd';

import { enquireScreen } from 'enquire-js';
import codeblockLogo from '../assets/imgs/codeblockLogo.png';
import codeblockBlueLogo from '../assets/imgs/codeblockBlueLogo.png';
import closeIcon from '../assets/imgs/closeIcon.png';



class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    activefaq: PropTypes.bool,
    activeProduct: PropTypes.bool,
    activeHome: PropTypes.bool,
    handleclick: PropTypes.func,
    handleLoginFlag: PropTypes.func,
  }

  state = {
    menuVisible: false,
    sendCode: false,
    sendCount: 60,
    phone: null,
    code: null,
    menuMode: 'horizontal',
  };
  sendInv = null
  componentDidMount() {
    let loginTime = localStorage.getItem('loginTime')
    if (loginTime && parseInt(loginTime) + 2 * 3600 * 1000 < new Date().getTime() ) {
      localStorage.removeItem('phone')
    }
    enquireScreen((b) => {
      this.setState({ menuMode: b ? 'inline' : 'horizontal' });
    });
  }
  componentWillUnmount() {
    this.setState({loginModal: false})
    this.initMask(false)
    if (this.sendInv) {
      clearInterval(this.sendInv)
    }
   
  }
  initMask(maskShow=true, scrollTopNo=0) {
    let scrollTop = scrollTopNo
    let bodyClass = 'modalOpen'
    let classList =  window.document.body.classList
    if (maskShow) {
      scrollTop = document.scrollingElement.scrollTop  ||
      document.documentElement.scrollTop || 
      document.body.scrollTop;
      document.body.style.top = -scrollTop + 'px';
      classList.add(bodyClass)
      
    } else {
      classList.remove(bodyClass)
      document.scrollingElement.scrollTop = document.documentElement.scrollTop = document.body.scrollTop = scrollTop;
    }
  }
  handleShowMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible});
  }

  handleMenuClick = (menu) => {
    if (menu.key === 'Login') {
      if (!localStorage.getItem('phone')){
        this.handleLogin(true)
      }
      this.setState({ menuVisible: false});
      return
    } 
    this.props.handleclick(menu.key);
    this.setState({ menuVisible: false});
  }
  handleLogin = (flag=false) => {
    console.log(flag)
    this.initMask(flag)
    this.setState({loginModal: flag, sendCode: false, sendCount: 60})
  }
  handleSend = () => {
    if (this.state.sendCode || !this.state.phone) {
      return
    }
    let sendCount = 60
    this.sendInv = setInterval(() => {
      sendCount--
      if(sendCount <= 0) {
        this.setState({sendCode: false, sendCount: 60})
      } else {
        this.setState({sendCode: true, sendCount})
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(this.sendInv)
      this.setState({sendCode: false, sendCount: 60})
    }, 59000);
  }
  handleChangItem = (event, name) => {
    const {target: {value}} = event
    this.setState({[name]: value && value.replace(/[^\d]/g,'')})
  }
  handleSubmit =() => {
    // if (this.state.sendCode && this.state.code){
    if ( this.state.code){
        message.loading('Submiting...', 0)
      if (this.state.phone && this.state.phone === '9998871212' && this.state.code && this.state.code === '123456') {
         this.handleLogin(false)
        localStorage.setItem('phone', this.state.phone)
        localStorage.setItem('loginTime', new Date().getTime())
        message.destroy()
      } else {
        setTimeout(() => {
          message.destroy()
          message.error('error otp', 2)
        }, 1000);
      }
     
    }
   
  }
  render() {
    const { activefaq, activeProduct, activeHome, handleclick, isMobile } = this.props;
    const { menuMode, menuVisible, loginModal, sendCode, sendCount } = this.state;
    let phone = localStorage.getItem('phone')
    const menu = (
      <Menu mode="inline" id="nav" key="nav" onClick={(key) => this.handleMenuClick(key)}>
        <Menu.Item key="home" onClick={() => this.handleMenuClick('home')}>
          <span >Home</span>
        </Menu.Item>
        <Menu.Item key="Product" onClick={() => this.handleMenuClick('Product')}>
          <span >Product</span>
        </Menu.Item>
        <Menu.Item key="Contact" onClick={() => this.handleMenuClick('Contact')}>
          <span >Contact Us</span>
        </Menu.Item>
        <Menu.Item key="Login" onClick={() => !phone && this.handleLogin(true)}>
          <span >{phone  || 'Login'}</span>
        </Menu.Item>
      </Menu>
    );

    return (
    <header className={[this.props.className, isMobile ? "phoneTop" : "" ].join(' ')}>
      <div className={["headerWrapper", isMobile ? "phoneHeader" : ""].join(' ')}>
        <div className="logoBox">
          {this.props.className?<img src={codeblockBlueLogo} alt='logo'/>:<img src={codeblockLogo} alt='logo'/>}
        </div>
        {
          menuMode === 'horizontal' ? (
          <div className="menuBox">
            <div className={activeHome ? 'active-btn' : 'button'} onClick={() => handleclick('home')}>
              <Button>Home</Button>
            </div>
            <div className="splitters" />
            <div className={activeProduct ? 'active-btn' : 'button'} onClick={() => handleclick('Product')}>
              <Button>Product</Button>
            </div>
            <div className="splitters" />
            <div className={activefaq ? 'active-btn' : 'button'} onClick={() => handleclick('Contact')}>
              <Button>Contact Us</Button>
            </div>
            <div className="splitters" />
            <div className={  'button'} onClick={() => !phone &&this.handleLogin(true)}>
              <Button>{phone  || 'Login'}</Button>
            </div>
          </div>) : (
            <Popover
              overlayClassName="popover-menu"
              placement="bottomRight"
              content={menu}
              trigger="click"
              visible={menuVisible}
              arrowPointAtCenter
              onVisibleChange={this.handleShowMenu}
            >
              <svg className="nav-phone-menu" onClick={this.handleShowMenu}>
                <use xlinkHref="#iconmenu1" />
              </svg>
            </Popover> 
          )
        }
      </div>
      {
        loginModal && <div className="loginModal">
         
          <div className="content">
            <div className="top">
              <img src={codeblockLogo} alt={'logo'}/>
              <div className="close" onClick={() =>this.handleLogin()}>
                <img alt={'close'} src={closeIcon}/>
              </div>
            </div>
            <div className="info">
              <div className="title">Phone</div>
              <div className="item">
                 <input className="inputItem" maxLength={10} value={this.state.phone}  onChange={(event)=>this.handleChangItem(event, 'phone')} type={'tel'} />
              </div>
              <div className="title">OTP</div>
              <div className="item">
                 <input className="inputItem" maxLength={6} value={this.state.code} onChange={(event)=>this.handleChangItem(event, 'code')}  type={'tel'}/>
                 {/* <div className="send" onClick={this.handleSend}>{sendCode? sendCount + 's': 'send'}</div> */}
                 <div className="send" onClick={() => {}}>{sendCode? sendCount + 's': 'send'}</div>
              </div>
              <div onClick={this.handleSubmit} className={"action "+ (this.state.code  && "do")}>
              LOGIN
              </div>
            </div>
            
          </div>
        </div>
      }
    </header>
    );
  }
}

export default Header;
